import React, { Component } from 'react';

class HeaderLogo extends Component {
  render() {
    return (
      <div className="header-logo">
        Sorty
      </div>
    )
  }
}

export default HeaderLogo;
