import React from 'react';

const BucketIcon = () => {
    return (

      <svg className="btn-icon" width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 17.8814C27.8814 17.8814 30.4903 18.1356 32.3982 18.5463C33.1186 18.7027 33.7416 18.8787 34.2478 19.0939C33.8389 17.588 32.9434 15.8475 31.4832 14.4785C30.5487 13.5984 29.3805 12.8553 27.9398 12.425C27.6673 12.7379 27.2779 12.9531 26.8301 12.9531H23.1699C22.7221 12.9531 22.3133 12.7379 22.0602 12.425C20.6195 12.8553 19.4513 13.5984 18.5168 14.4785C17.0566 15.8475 16.1611 17.588 15.7522 19.0939C16.2584 18.8787 16.8814 18.7027 17.5823 18.5463C19.4903 18.1356 22.1186 17.8814 25 17.8814ZM23.1699 10H26.8301C27.4531 10 27.9982 10.4107 28.2124 10.9778C29.9451 11.4668 31.3664 12.3468 32.4956 13.4029C34.8319 15.6128 35.9027 18.6441 35.9805 20.7171C36 20.7562 36 20.7953 36 20.8344V20.8735V20.9713H35.9805C35.9805 21.0104 35.9805 21.0495 35.9611 21.0887L33.3133 38.631C33.2743 38.9439 33.0212 39.1786 32.7292 39.2373C30.5876 39.7458 27.7841 40 25 40C22.1965 40 19.3929 39.7458 17.2319 39.2373C16.9398 39.1591 16.7257 38.9244 16.6867 38.631L14.0389 21.0887C14.0195 21.0495 14.0195 21.0104 14.0195 20.9713H14V20.8735V20.8344C14 20.7953 14 20.7562 14 20.7171C14.0779 18.6441 15.1681 15.6128 17.5044 13.4029C18.6336 12.3468 20.0549 11.4668 21.7876 10.9778C21.9823 10.4107 22.5274 10 23.1699 10ZM34.2673 22.5554C33.7416 22.7705 33.1186 22.9661 32.3982 23.103C30.4903 23.5137 27.8814 23.7679 25 23.7679C22.1186 23.7679 19.4903 23.5137 17.5823 23.103C16.8814 22.9661 16.2584 22.7705 15.7327 22.5554L18.0496 37.9074C20.0159 38.3181 22.508 38.5137 25 38.5137C27.492 38.5137 29.9841 38.3181 31.9504 37.9074L34.2673 22.5554ZM15.4796 20.7953V20.8735C15.6159 21.0887 16.5115 21.382 17.8938 21.6754C19.7044 22.0665 22.2159 22.3012 25 22.3012C27.7841 22.3012 30.2956 22.0665 32.1062 21.6754C33.4885 21.382 34.3841 21.0887 34.5204 20.8735V20.7953C34.4619 20.5997 33.5469 20.2868 32.1062 19.9935C30.2956 19.6023 27.7841 19.3481 25 19.3481C22.2159 19.3481 19.7044 19.6023 17.8938 19.9935C16.4531 20.2868 15.5381 20.5997 15.4796 20.7953Z" />
      <path fill-rule="evenodd" clip-rule="evenodd" d="M40 2H10C5.58172 2 2 5.58172 2 10V40C2 44.4183 5.58172 48 10 48H40C44.4183 48 48 44.4183 48 40V10C48 5.58172 44.4183 2 40 2ZM10 0C4.47715 0 0 4.47715 0 10V40C0 45.5228 4.47715 50 10 50H40C45.5228 50 50 45.5228 50 40V10C50 4.47715 45.5228 0 40 0H10Z" />
      </svg>
    )
};

export default BucketIcon;
