import React from 'react';

const SortIcon = () => {
  return (
    <svg className="btn-icon-secondary btn-sort-icon" width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.8644 1.88047C18.3837 1.88047 18.8046 1.45951 18.8046 0.940233C18.8046 0.420956 18.3837 0 17.8644 0L0.940207 0C0.420931 0 -2.67029e-05 0.420956 -2.67029e-05 0.940233C-2.67029e-05 1.45951 0.420931 1.88047 0.940207 1.88047L17.8644 1.88047Z" />
    <path d="M15.984 7.52182C16.5032 7.52182 16.9242 7.10087 16.9242 6.58159C16.9242 6.06231 16.5032 5.64136 15.984 5.64136L0.940239 5.64136C0.420961 5.64136 5.72205e-06 6.06231 5.72205e-06 6.58159C5.72205e-06 7.10087 0.420961 7.52182 0.940239 7.52182L15.984 7.52182Z" />
    <path d="M13.1633 13.1633C13.6826 13.1633 14.1035 12.7423 14.1035 12.2231C14.1035 11.7038 13.6826 11.2828 13.1633 11.2828L0.940259 11.2828C0.420982 11.2828 2.57492e-05 11.7038 2.57492e-05 12.2231C2.57492e-05 12.7423 0.420982 13.1633 0.940259 13.1633L13.1633 13.1633Z" />
    <path d="M10.3425 18.8047C10.8618 18.8047 11.2828 18.3837 11.2828 17.8644C11.2828 17.3452 10.8618 16.9242 10.3425 16.9242L0.940216 16.9242C0.42094 16.9242 -1.62125e-05 17.3452 -1.62125e-05 17.8644C-1.62125e-05 18.3837 0.42094 18.8047 0.940216 18.8047L10.3425 18.8047Z" />
    <path d="M22.357 13.2102C21.9898 12.843 21.3945 12.843 21.0273 13.2102C20.6601 13.5773 20.6601 14.1727 21.0273 14.5398L25.0164 18.5289C25.3836 18.8961 25.9789 18.8961 26.3461 18.5289C26.7133 18.1617 26.7133 17.5664 26.3461 17.1992L22.357 13.2102Z" />
    <path d="M30.3351 14.5398C30.7023 14.1726 30.7023 13.5773 30.3351 13.2101C29.9679 12.8429 29.3726 12.8429 29.0054 13.2101L25.0164 17.1992C24.6492 17.5664 24.6492 18.1617 25.0164 18.5289C25.3836 18.8961 25.9789 18.8961 26.3461 18.5289L30.3351 14.5398Z" />
    <path d="M26.6215 0.940233C26.6215 0.420956 26.2005 0 25.6813 0C25.162 0 24.741 0.420956 24.741 0.940233L24.741 17.8644C24.741 18.3837 25.162 18.8047 25.6813 18.8047C26.2005 18.8047 26.6215 18.3837 26.6215 17.8644L26.6215 0.940233Z" />
    </svg>

  )
}

export default SortIcon;
