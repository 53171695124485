import React from 'react';

const BubbleIcon = () => {
  return (
        <svg className='btn-icon' width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M40 2H10C5.58172 2 2 5.58172 2 10V40C2 44.4183 5.58172 48 10 48H40C44.4183 48 48 44.4183 48 40V10C48 5.58172 44.4183 2 40 2ZM10 0C4.47715 0 0 4.47715 0 10V40C0 45.5228 4.47715 50 10 50H40C45.5228 50 50 45.5228 50 40V10C50 4.47715 45.5228 0 40 0H10Z" />
        <path d="M29.7789 16.6879C29.7789 12.7195 26.5632 9.46948 22.5605 9.46948C18.5921 9.46948 15.3421 12.6853 15.3421 16.6879C15.3421 20.6563 18.5579 23.9063 22.5605 23.9063C26.5632 23.9063 29.7789 20.6563 29.7789 16.6879ZM22.5947 22.1958C19.5842 22.1958 17.121 19.7326 17.121 16.7221C17.121 13.7116 19.5842 11.2484 22.5947 11.2484C25.6053 11.2484 28.0684 13.7116 28.0684 16.7221C28.0684 19.7326 25.6053 22.1958 22.5947 22.1958Z" />
        <path d="M22.5947 12.48C20.2684 12.48 18.3526 14.3958 18.3526 16.7221C18.3526 17.201 18.7289 17.5773 19.2079 17.5773C19.6868 17.5773 20.0632 17.201 20.0632 16.7221C20.0632 15.3195 21.1921 14.1905 22.5947 14.1905C23.0737 14.1905 23.45 13.8142 23.45 13.3352C23.45 12.8563 23.0737 12.48 22.5947 12.48Z" />
        <path d="M31.8316 24.5221C27.5553 24.5221 24.1 28.0116 24.1 32.2537C24.1 36.4958 27.5895 39.9853 31.8316 39.9853C36.0737 39.9853 39.5974 36.5642 39.5974 32.2879C39.5974 28.0116 36.1079 24.5221 31.8316 24.5221ZM31.8316 38.3089C28.5131 38.3089 25.8105 35.6063 25.8105 32.2879C25.8105 28.9695 28.5131 26.2326 31.8316 26.2326C35.15 26.2326 37.8868 28.9353 37.8868 32.2879C37.8868 35.6405 35.1842 38.3089 31.8316 38.3089Z" />
        <path d="M36.6895 14.4984C34.329 14.4984 32.379 16.4142 32.379 18.8089C32.379 21.1695 34.2947 23.1195 36.6895 23.1195C39.05 23.1195 41 21.2037 41 18.8089C41 16.4142 39.0842 14.4984 36.6895 14.4984ZM36.6895 21.3747C35.2526 21.3747 34.0895 20.2116 34.0895 18.7747C34.0895 17.3379 35.2526 16.1747 36.6895 16.1747C38.1263 16.1747 39.2895 17.3379 39.2895 18.7747C39.2895 20.2116 38.1263 21.3747 36.6895 21.3747Z" />
        <path d="M17.0868 27.8406C17.0868 25.48 15.1711 23.53 12.7763 23.53C10.4158 23.53 8.5 25.48 8.5 27.8406C8.5 30.2011 10.4158 32.1511 12.8105 32.1511C15.1711 32.1511 17.0868 30.2011 17.0868 27.8406ZM12.8105 30.4406C11.3737 30.4406 10.2105 29.2774 10.2105 27.8406C10.2105 26.4037 11.3737 25.2406 12.8105 25.2406C14.2474 25.2406 15.4105 26.4037 15.4105 27.8406C15.3763 29.2774 14.2132 30.4406 12.8105 30.4406Z" />
        <path d="M18.4553 33.4169C16.6421 33.4169 15.1711 34.8879 15.1711 36.7011C15.1711 38.5142 16.6421 39.9853 18.4553 39.9853C20.2684 39.9853 21.7395 38.5142 21.7395 36.7011C21.7395 34.8879 20.2684 33.4169 18.4553 33.4169ZM18.4553 38.2748C17.6 38.2748 16.8816 37.5563 16.8816 36.7011C16.8816 35.8458 17.6 35.1274 18.4553 35.1274C19.3105 35.1274 20.029 35.8458 20.029 36.7011C20.029 37.5563 19.3105 38.2748 18.4553 38.2748Z" />
        <path d="M31.8316 27.7379C29.3342 27.7379 27.3158 29.7563 27.3158 32.2537C27.3158 32.7327 27.6921 33.109 28.1711 33.109C28.65 33.109 29.0263 32.7327 29.0263 32.2537C29.0263 30.7142 30.2921 29.4484 31.8316 29.4484C32.3105 29.4484 32.6868 29.0721 32.6868 28.5932C32.6868 28.1142 32.3105 27.7379 31.8316 27.7379Z" />
        </svg>
  )
};

export default BubbleIcon;
