import React from 'react';

const InsertionIcon = () => {
  return (
    <svg className="btn-icon" width="60" height="60" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fill-rule="evenodd" clip-rule="evenodd" d="M40 2H10C5.58172 2 2 5.58172 2 10V40C2 44.4183 5.58172 48 10 48H40C44.4183 48 48 44.4183 48 40V10C48 5.58172 44.4183 2 40 2ZM10 0C4.47715 0 0 4.47715 0 10V40C0 45.5228 4.47715 50 10 50H40C45.5228 50 50 45.5228 50 40V10C50 4.47715 45.5228 0 40 0H10Z" />
    <path d="M9.81846 28V25.92L11.5585 25.44V25.26H8.07846V24H12.6385V26.2L10.7785 26.74V26.92H11.0185C11.2651 26.92 11.5085 26.96 11.7485 27.04C11.9885 27.1133 12.1985 27.23 12.3785 27.39C12.5651 27.55 12.7151 27.75 12.8285 27.99C12.9418 28.2233 12.9985 28.5 12.9985 28.82V28.94C12.9985 29.2533 12.9451 29.5433 12.8385 29.81C12.7385 30.0767 12.5851 30.31 12.3785 30.51C12.1785 30.7033 11.9285 30.8567 11.6285 30.97C11.3285 31.0833 10.9851 31.14 10.5985 31.14C10.2051 31.14 9.84846 31.0767 9.52846 30.95C9.20846 30.8233 8.93513 30.65 8.70846 30.43C8.48846 30.21 8.31846 29.9567 8.19846 29.67C8.07846 29.3833 8.01846 29.08 8.01846 28.76V28.34H9.33846V28.7C9.33846 29.02 9.4418 29.2967 9.64846 29.53C9.85513 29.7633 10.1651 29.88 10.5785 29.88C10.9318 29.88 11.2018 29.79 11.3885 29.61C11.5818 29.43 11.6785 29.1933 11.6785 28.9C11.6785 28.5867 11.5751 28.36 11.3685 28.22C11.1685 28.0733 10.9118 28 10.5985 28H9.81846Z" />
    <path d="M21.0385 24H25.9585V26.06L23.4285 29.76C23.2951 29.96 23.1885 30.1333 23.1085 30.28C23.0351 30.42 22.9985 30.58 22.9985 30.76V31H21.5785V30.7C21.5785 30.5667 21.5885 30.4467 21.6085 30.34C21.6285 30.2333 21.6585 30.1267 21.6985 30.02C21.7451 29.9133 21.8051 29.8 21.8785 29.68C21.9518 29.56 22.0418 29.42 22.1485 29.26L24.7585 25.44V25.26H21.0385V24Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M43 24C43 22.3431 41.6568 21 40 21H33C31.3431 21 30 22.3431 30 24V31C30 32.6569 31.3431 34 33 34H40C41.6568 34 43 32.6569 43 31V24ZM34.0985 24V27.88H36.2585C36.2918 27.7667 36.3685 27.67 36.4885 27.59C36.6085 27.5033 36.7651 27.46 36.9585 27.46C37.2518 27.46 37.4885 27.5567 37.6685 27.75C37.8485 27.9367 37.9385 28.22 37.9385 28.6C37.9385 29 37.8151 29.3133 37.5685 29.54C37.3285 29.7667 36.9985 29.88 36.5785 29.88C36.1585 29.88 35.8385 29.7667 35.6185 29.54C35.4051 29.3067 35.2985 29.0133 35.2985 28.66V28.54H33.9785V28.78C33.9785 29.12 34.0351 29.4367 34.1485 29.73C34.2685 30.0167 34.4385 30.2667 34.6585 30.48C34.8851 30.6867 35.1618 30.85 35.4885 30.97C35.8151 31.0833 36.1918 31.14 36.6185 31.14C37.0451 31.14 37.4218 31.07 37.7485 30.93C38.0751 30.79 38.3485 30.6033 38.5685 30.37C38.7951 30.13 38.9651 29.8533 39.0785 29.54C39.1985 29.2267 39.2585 28.8933 39.2585 28.54V28.48C39.2585 28.1467 39.2085 27.84 39.1085 27.56C39.0151 27.28 38.8785 27.04 38.6985 26.84C38.5185 26.64 38.2985 26.4833 38.0385 26.37C37.7785 26.2567 37.4851 26.2 37.1585 26.2C36.7251 26.2 36.3785 26.2967 36.1185 26.49C35.8651 26.6767 35.6918 26.9 35.5985 27.16H35.4185V25.26H38.7785V24H34.0985Z" />
    <path fill-rule="evenodd" clip-rule="evenodd" d="M33.3074 10.0556C35.0356 12.382 36.0683 15.5196 36.8201 18.2764C36.9436 18.7293 36.6766 19.1965 36.2237 19.3201C35.7708 19.4436 35.3035 19.1766 35.18 18.7237C34.4318 15.9804 33.4645 13.1179 31.9427 11.0694C31.19 10.0561 30.3229 9.27194 29.3083 8.7917C28.3021 8.31547 27.1005 8.11378 25.6293 8.34014C22.8073 8.77432 21.1136 10.8203 20.0416 13.5598C19.2413 15.605 18.8325 17.933 18.5723 20.0245L20.9235 17.6843C21.2868 17.387 21.8223 17.4406 22.1196 17.8039C22.4169 18.1672 22.3633 18.7027 22 19L18.0383 22.6579C17.675 22.9552 17.1395 22.9016 16.8422 22.5383L13.6843 18.5765C13.387 18.2132 13.4406 17.6777 13.8039 17.3804C14.1672 17.0831 14.7027 17.1367 15 17.5L16.8751 19.8939C17.1424 17.7203 17.5771 15.1926 18.4585 12.9403C19.6365 9.92984 21.6927 7.22581 25.3708 6.65991C27.1496 6.38622 28.6979 6.622 30.0356 7.25514C31.3647 7.88426 32.4351 8.88135 33.3074 10.0556Z" />
    </svg>
  );
};

export default InsertionIcon;
